<template>
<div>
    <div style="
    background: white;
    font-size: 12px;
    width: 21cm;
    height: 29.7cm;
    display: block;
    margin: 0 auto;">
        <img src="../../assets/label/china.png" alt="" style="width: 21cm;height: 29.7cm;">
        <table style="position: absolute;width: 21cm;height: 29.7cm;z-index: 2;top: 0px;">
            <tr>
                <td style="width:20%;">
                    <div style="position:absolute;top: 240px;left: 294px;writing-mode: vertical-lr;height: 400px;">
                        {{printModel.printItem.product}}</div>
                    <span
                        style="position:absolute;top: 180px;left: 238px;writing-mode: vertical-lr;height: 200px;">{{printModel.printItem.lot}}</span>
                    <span
                        style="position:absolute;top: 375px;left: 239px;writing-mode: vertical-lr;height: 200px;">{{printModel.printItem.itemCode}}</span>
                    <span
                        style="position:absolute;top: 220px;left: 195px;writing-mode: vertical-lr;height: 200px;">{{printModel.printItem.netWeight}}</span>
                    <span
                        style="position:absolute;top: 210px;left: 179px;writing-mode: vertical-lr;height: 200px;">610 X 10</span>
                    <span
                        style="position:absolute;top: 460px;left: 172px;writing-mode: vertical-lr;height: 200px;color:#2a5a49;">{{printModel.printItem.noNumber}}</span>
                </td>
                <td style="width:20%;">
                    <div style="position:absolute;top: 240px;left: 691px;writing-mode: vertical-lr;height: 400px;">
                        Peppermint Liquid Extract</div>
                    <span
                        style="position:absolute;top: 180px;left: 636px;writing-mode: vertical-lr;height: 200px;">WT-P-0263080</span>
                    <span
                        style="position:absolute;top: 375px;left: 636px;writing-mode: vertical-lr;height: 200px;">R10137</span>
                    <span
                        style="position:absolute;top: 220px;left: 592px;writing-mode: vertical-lr;height: 200px;">5.1</span>
                    <span
                        style="position:absolute;top: 210px;left: 576px;writing-mode: vertical-lr;height: 200px;">610
                        X 10</span>
                    <span
                        style="position:absolute;top: 460px;left: 569px;writing-mode: vertical-lr;height: 200px;color:#2a5a49;">111</span>
                </td>
                <td style="width:20%;">
                    <div style="position:absolute;top: 780px;left: 294px;writing-mode: vertical-lr;height: 400px;">
                        Peppermint Liquid Extract</div>
                    <span
                        style="position:absolute;top: 720px;left: 238px;writing-mode: vertical-lr;height: 200px;">WT-P-0263080</span>
                    <span
                        style="position:absolute;top: 920px;left: 239px;writing-mode: vertical-lr;height: 200px;">R10137</span>
                    <span
                        style="position:absolute;top: 760px;left: 195px;writing-mode: vertical-lr;height: 200px;">5.1</span>
                    <span
                        style="position:absolute;top: 740px;left: 179px;writing-mode: vertical-lr;height: 200px;">610
                        X 10</span>
                    <span
                        style="position:absolute;top: 1000px;left: 172px;writing-mode: vertical-lr;height: 200px;color:#2a5a49;">111</span>
                </td>
                <td style="width:20%;">
                    <div style="position:absolute;top: 780px;left: 691px;writing-mode: vertical-lr;height: 400px;">
                        Peppermint Liquid Extract</div>
                    <span
                        style="position:absolute;top: 720px;left: 636px;writing-mode: vertical-lr;height: 200px;">WT-P-0263080</span>
                    <span
                        style="position:absolute;top: 920px;left: 636px;writing-mode: vertical-lr;height: 200px;">R10137</span>
                    <span
                        style="position:absolute;top: 760px;left: 592px;writing-mode: vertical-lr;height: 200px;">5.1</span>
                    <span
                        style="position:absolute;top: 740px;left: 576px;writing-mode: vertical-lr;height: 200px;">610
                        X 10</span>
                    <span
                        style="position:absolute;top: 1000px;left: 570px;writing-mode: vertical-lr;height: 200px;color:#2a5a49;">111</span>
                </td>
            </tr>
        </table>
    </div>
</div>
</template>
<script>
export default {
  props:['printModel'],
  data() {
    return {
    };
  },
  methods: {
  },
  mounted() {
      console.log("this.printModel")
      console.log(this.printModel)
  },
};
</script>
