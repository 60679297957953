<template>
  <div>
    <div id="printDiv" v-show="printShow">
      <LabelCn
        v-show="printcnShow"
        :printModel="this.$data"
        style="height-max: 1000px; width: 100vw; padding: 40px"
      ></LabelCn>
      <LabelEn
        v-show="printenShow"
        :printModel="this.$data"
        style="height-max: 1000px; width: 100vw; padding: 40px"
      ></LabelEn>
      <LabelEn1
        v-show="printen1Show"
        :printModel="this.$data"
        style="height-max: 1000px; width: 100vw; padding: 40px"
      ></LabelEn1>
    </div>
    <div class="app-home-table">
      <el-row style="padding: 10px">
        <el-col :span="6">
          <span>选项：</span>
          <el-select v-model="printType" placeholder="请选择">
            <el-option
              v-for="item in printTypeList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-col>
        <el-col :span="6">
          <div>
            <el-upload
              action=""
              :auto-upload="true"
              :on-change="onChange"
              :limit="1"
              style="float: left; margin: 0px 10px 0px 0px"
            >
              <el-button type="success">导入</el-button>
            </el-upload>
          </div>
        </el-col>
      </el-row>

      <div
        :class="[{ 'table-list': true }, screen_ratio]"
        v-loading.fullscreen.lock="loading"
        element-loading-spinner="el-icon-loading"
        element-loading-text="Desperately loading..."
        style="height: 77vh"
      >
        <el-table
          :data="uploadItemList"
          style="width: 100%"
          :header-cell-style="{ background: '#FAFAFA' }"
        >
          <el-table-column
            type="index"
            label="Number"
            width="80"
          ></el-table-column>

          <el-table-column prop="type" label="type"></el-table-column>
          <el-table-column prop="product" label="Product"></el-table-column>
          <el-table-column
            prop="printType"
            label="Print Type"
          ></el-table-column>
          <!-- <el-table-column prop="shipTo" label="Ship To"></el-table-column>
          <el-table-column prop="itemCode" label="Itemcode#"></el-table-column>
          <el-table-column prop="note1" label="Note 1"></el-table-column>
          <el-table-column prop="invoiceNumbe" label="Invoice Numbe"></el-table-column>
          <el-table-column prop="poNumbe" label="PO Numbe"></el-table-column>
          <el-table-column prop="lot" label="Lot #"></el-table-column>
          <el-table-column prop="netWeight" label="Net Weight"></el-table-column>
          <el-table-column prop="noNumber" label=" No."></el-table-column>
          <el-table-column prop="note2" label="Note 2"></el-table-column> -->
          <el-table-column prop="createTime" label="Create Time" width="140">
            <template slot="header">
              <span>Create Time</span>
              <span
                :class="{
                  'table-icon': true,
                  'table-icon-rotate': createTimeSort,
                }"
              >
                <i class="el-icon-top"></i>
              </span>
            </template>
            <template slot-scope="scope">
              <span>{{ getTime(scope.row.createTime) }}</span>
            </template>
          </el-table-column>

          <el-table-column label="Action" width="180px">
            <template slot-scope="scope">
              <el-button
                @click="handleClick(scope.row)"
                type="primary"
                style="float: left"
                >详情</el-button
              >
              <el-button
                @click="printBtn(scope.row)"
                type="primary"
                style="float: left"
                >打印</el-button
              >
            </template>
          </el-table-column>
        </el-table>

        <div class="table-pagition">
          <el-pagination
            background
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="params.pageIndex"
            :page-size="params.pageSize"
            layout="total, prev, pager, next"
            :total="total"
          ></el-pagination>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import base from "@/common/base.js";
import xlsx from "xlsx";
import printJS from "print-js";
import LabelCn from "./labelcnPrint.vue";
import LabelEn from "./labelenPrint.vue";
import LabelEn1 from "./labelen1Print.vue";
export default {
  mixins: [base],
  components: { LabelCn, LabelEn, LabelEn1 },
  data() {
    return {
      loading: false,
      total: 0,
      tableData: [],
      params: {
        pageIndex: 1,
        pageSorts: [
          {
            column: "createTime",
            asc: false,
          },
        ],
        pageSize: 100,
        itemCode: "",
        productName: "",
        lot: "",
      },
      screen_ratio: "",
      createTimeSort: true,
      printShow: false,
      printcnShow: false,
      printenShow: false,
      printen1Show: false,
      printType: "",
      printTypeList: [
        {
          id: "0",
          name: "all",
        },
        {
          id: "1",
          name: "common",
        },
        {
          id: "2",
          name: "Organic Glycerin",
        },
      ],
      uploadItemList: [
        {
          type: "",
          shipTo: "",
          product: "",
          itemCode: "",
          note1: "",
          invoiceNumbe: "",
          poNumbe: "",
          lot: "",
          netWeight: "",
          noNumber: "",
          note2: "",
          createTime: "",
          printType: "",
        },
      ],
      printItem: {
        type: "",
        shipTo: "",
        product: "",
        itemCode: "",
        note1: "",
        invoiceNumbe: "",
        poNumbe: "",
        lot: "",
        netWeight: "",
        noNumber: "",
        note2: "",
        createTime: "",
        printType: "",
      },
    };
  },
  methods: {
    async onChange(file) {
      let dataBinary = await readFile(file.raw);
      let workBook = xlsx.read(dataBinary, { type: "binary", cellDates: true });
      let workSheet = workBook.Sheets[workBook.SheetNames[0]];
      const data = xlsx.utils.sheet_to_json(workSheet);
      this.uploadItemList = [];
      data.filter((item) => {
        let Newitem = {};
        Newitem.type = item["type"];
        Newitem.shipTo = item["Ship To"];
        Newitem.product = item["Product"];
        Newitem.itemCode = item["Item#/Stock#/Part#/code#"];
        Newitem.note1 = item["Note 1"];
        Newitem.invoiceNumbe = item["Invoice Numbe"];
        Newitem.poNumbe = item["PO Numbe"];
        Newitem.lot = item["Lot #"];
        Newitem.netWeight = item["Net Weight "];
        Newitem.noNumber = item[" No."];
        Newitem.note2 = item["Note 2"];
        Newitem.printType = item["Print Type"];
        Newitem.createTime = new Date();
        this.uploadItemList.push(Newitem);
      });
      this.total = this.uploadItemList.length;
      this.tableData = this.uploadItemList;
      console.log(this.uploadItemList);
    },
    handleClick(row) {
      console.log(row);
    },
    printBtn(row) {
      this.printShow = true;
      this.printItem = row;
      this.printType = row.printType;
      if (this.printType == "chinese") {
        this.printcnShow = true;
      } else if (this.printType == "organic") {
        this.printenShow = true;
      } else if (this.printType == "non_organic") {
        this.printen1Show = true;
      }
      const style = "@page {size: auto; margin:0} @media print{} ";
      document.title = "";
      setTimeout(() => {
        printJS({
          printable: "printDiv",
          type: "html",
          style: style,
          scanStyles: false,
        });
      }, 1.5);
      setTimeout(() => {
        this.printShow = false;
        if (this.printType == "chinese") {
          this.printcnShow = false;
        } else if (this.printType == "organic") {
          this.printenShow = false;
        } else if (this.printType == "non_organic") {
          this.printen1Show = false;
        }
      }, 3);
    },
    handleSizeChange(val) {
      this.params.pageSize = val;
      this.getData(this.params);
    },
    handleCurrentChange(val) {
      this.params.pageIndex = val;
      this.getData(this.params);
    },
    getTime(time) {
      if (time) {
        return this.formatTime(time, `MM-dd-yyyy hh:mm`);
      } else {
        return "";
      }
    },
  },
  mounted() {
  },
};
export const readFile = (file) => {
  return new Promise((resolve) => {
    let reader = new FileReader();
    reader.readAsBinaryString(file);
    reader.onload = (ev) => {
      resolve(ev.target.result);
    };
  });
};
</script>
<style lang="scss" scoped>
.app-home {
  $size-16-9: 56vh;
  $size-16-10: 43vh;
  $size-4-3: 30vh;
  $size-3-2: 38vh;
  width: 100%;
  height: 100%;
  overflow: auto;
  display: flex;
  flex-direction: column;
  .app-home-head {
    position: relative;
    background-color: #fff;
    height: 66px;
    line-height: 66px;
    font-size: 20px;
    font-weight: 600;
    color: #000;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
    .app-home-head-username {
      font-size: 14px;
      color: #333;
      font-weight: 400;
      position: absolute;
      top: 0;
      right: 10px;
      height: 66px;
      line-height: 66px;
      cursor: pointer;
    }
  }
  .app-home-preview {
    box-sizing: content-box;
    padding: 10px 120px;
    .preview-content {
      box-sizing: border-box;
      background-color: #fff;
      height: 80px;
      width: 100%;
      padding: 10px 0;
      display: flex;
      > div {
        width: 33.33%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        > div:nth-of-type(1) {
          font-size: 14px;
          color: #999;
        }
        > div:nth-of-type(2) {
          margin-top: 10px;
          font-size: 22px;
          color: #000;
        }
      }
      > div:nth-of-type(2) {
        border-right: 1px solid #e8e8e8;
        border-left: 1px solid #e8e8e8;
      }
    }
  }
  .app-home-table {
    background-color: #fff;
    margin: 10px;
    // margin-top: 0;
    .table-name {
      height: 68px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-left: 140px;
      padding-right: 34px;
      font-size: 20px;
      color: #000;
      border-bottom: 1px solid #e8e8e8;
    }

    .ratio-16-9 {
      ::v-deep .el-table__body-wrapper {
        height: $size-16-9;
        overflow-y: auto;
        border: none;
      }
    }
    .ratio-4-3 {
      ::v-deep .el-table__body-wrapper {
        height: $size-4-3;
        overflow-y: auto;
        border: none;
      }
    }
    .ratio-16-10 {
      ::v-deep .el-table__body-wrapper {
        height: 58vh;
        overflow-y: auto;
        border: none;
      }
    }
    .ratio-3-2 {
      ::v-deep .el-table__body-wrapper {
        height: $size-3-2;
        overflow-y: auto;
        border: none;
      }
    }
    .table-list {
      overflow: hidden;
      padding-left: 60px;
      padding-right: 34px;
      .table-edit {
        display: inline-block;
        border-radius: 3px;
        padding: 2px 6px;
        cursor: pointer;
        color: #1890ff;
        &:hover {
          background-color: rgba(225, 225, 225, 0.4);
        }
      }
      .table-edit:hover {
        opacity: 0.8;
      }
      .table-pagition {
        text-align: right;
        padding: 20px 0;
        margin-top: 20px;
        .el-pagination {
          display: inline-block;
        }
      }
    }
  }
  .table-icon {
    margin-left: 10px;
    cursor: pointer;
    transition: transform 300ms;
    display: inline-block;
    &:hover {
      color: #409eff;
    }
  }
  .table-icon-rotate {
    transform: rotateZ(180deg);
  }
}
</style>